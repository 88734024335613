import apiClient from 'helpers/api-client'
import { generateShortUuid } from 'helpers/generate/uuid'
import { getIdToken } from 'helpers/auth'
import isEmpty from 'helpers/is-empty'
import { isGuestMode } from 'helpers/cookie'
import { sendLog } from 'helpers/log'

export const getAccumulationTipping = async (sessionID: string) => {
  return await apiClient({
    url: `/session/v1/tip/${sessionID}/supporter-accumulation`,
    method: 'GET',
    token: getIdToken(),
  })
}

export const onLeaveSession = async (sessionID: string) => {
  return await apiClient({
    url: `/session/v1/live/${sessionID}/leave`,
    method: 'POST',
    token: getIdToken(),
  })
}

export const onSendTippingCoin = async (
  sessionID,
  coinID: string,
) => {
  return await apiClient({
    url: `/session/v2/tip/${sessionID}/deduct`,
    method: 'POST',
    data: {
      coin_id: coinID,
    },
    headers: { 'Idempotent-Key': generateShortUuid() },
    token: getIdToken(),
  })
}

export const getRemoteConfig = async (params = {}) => {
  return await apiClient({
    url: '/session/v1/live/remote-config',
    method: 'GET',
    token: getIdToken(),
    params,
  })
}

interface IRecommendationSessionParams {
  page: number
  limit: number
  category_id?: string
  event_types?: string
  type?: string
  status?: string
}

export const getRecommendationSession = async ({
  category_id,
  type,
  page,
  limit,
}: IRecommendationSessionParams) => {
  const catID = category_id || ''
  const typeID = type || ''

  let url = !isGuestMode()
    ? '/recommendation/v1/session?' + catID + typeID
    : '/recommendation/guest/v1/session?' + catID + typeID

  return await apiClient({
    url: url,
    method: 'GET',
    token: isGuestMode() ? null : getIdToken(),
    params: {
      page,
      limit,
      sources: !isGuestMode() ? 'recommendation' : '',
    },
  })
}

export const getRecommendationSessionGuest = async (
  params: IRecommendationSessionParams,
) => {
  return await apiClient({
    url: '/recommendation/guest/v1/session?',
    method: 'GET',
    params,
  })
}

interface IGetParticipantListParams {
  sessionId: string
  page?: number
  limit?: number
  isComplete?: boolean
}

export async function getParticipantList({
  sessionId,
  page = 1,
  limit = 10,
  isComplete = true,
}: IGetParticipantListParams) {
  if (!sessionId) {
    throw 'SessionId is required'
  }

  const requestParams = { page, limit }

  if (isComplete) {
    requestParams['payment_status'] = 'completed'
  }

  return await apiClient({
    url: `/session/guest/v2/book/${sessionId}/participant`,
    method: 'GET',
    params: requestParams,
  })
}

export async function getSessionDetail(sessionId: string) {
  if (!sessionId) {
    throw 'SessionId is required'
  }

  return await apiClient({
    url: `/session/guest/v1/live/${sessionId}/detail`,
    method: 'GET',
  })
}

export async function getCreatorSession({ username, params }) {
  const idToken = getIdToken()
  return await apiClient({
    url: !isEmpty(idToken)
      ? `/session/v1/${username}/creator`
      : `/session/guest/v1/${username}/creator`,
    method: 'GET',
    params,
    token: !isEmpty(idToken) ? idToken : '',
  })
}

export async function bookSession({ sessionId, idempotentKey }) {
  try {
    const res = await apiClient({
      url: `/session/v2/book/${sessionId}/session`,
      method: 'POST',
      headers: { 'Idempotent-Key': idempotentKey },
      token: getIdToken(),
    })
    return res.data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}

export async function bookCheckoutSession({
  invoiceId,
  redirectionLink,
  idempotentKey,
}) {
  try {
    const res = await apiClient({
      url: '/session/v2/book/checkout',
      method: 'POST',
      headers: { 'Idempotent-Key': idempotentKey },
      token: getIdToken(),
      data: {
        invoice_id: invoiceId,
        redirection_link: redirectionLink,
      },
      timeout: 30000,
    })
    return res.data.data
  } catch (error) {
    sendLog(error)
    throw error
  }
}
